import { useLocation } from "react-router-dom";
import { useMemo } from "react";

/**
 * Custom hook to capture the next URL and allow overriding of query parameters.
 *
 * @returns {Object} - An object containing the next URL and a function to get the modified next URL.
 */
const useNext = () => {
  const location = useLocation(); // Get the current location (pathname and search)
  const nextUrl = useMemo(() => {
    return `${location.pathname.substring(1)}${location.search}`;
  }, [location]);

  /**
   * Get the modified next URL with overridden query parameters.
   *
   * @param {Object} overrides - Key-value pairs of query parameters to override.
   * @returns {string} - The modified next URL with the specified overrides.
   */
  const getNextUrlWithOverrides = (overrides = {}) => {
    const url = new URL(window.location.origin + nextUrl);
    Object.entries(overrides).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });
    return url.pathname + url.search;
  };

  return { nextUrl, getNextUrlWithOverrides };
};

export default useNext;
